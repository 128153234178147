import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Footer from '../components/Footer';
import '../styles.css';

const Gallery = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSong, setCurrentSong] = useState('');
  const [selectedSongIndex, setSelectedSongIndex] = useState(0); // Track selected song

  const images = [
    '/images/picture1.PNG',
    '/images/picture2.PNG',
    '/images/picture3.PNG',
    '/images/picture4.PNG',
    '/images/picture5.PNG',
    '/images/picture6.PNG',
    '/images/picture7.PNG',
    '/images/picture8.PNG',
    '/images/promo1.png',
    '/images/promo2.png',
    '/images/promo3.png',
    '/images/promo4.png',
    '/images/promo5.png',
    '/images/promo6.png',
    '/images/promo8.png',
    '/appicon.png',
    '/images/MainCharacter.png',
    '/images/MainCharacterGif.gif',
  ];

  const songs = [
    { name: 'Our Destiny', src: '/music/Our Destiny.wav' },
    { name: 'On Our Journey', src: '/music/On Our Journey.wav' },
    { name: 'Within', src: '/music/Within.wav' },
    { name: 'Upside Down', src: '/music/Upside Down.wav' },
    { name: 'To The Core', src: '/music/To The Core.wav' },
    { name: 'The Last Known', src: '/music/The Last Known.wav' },
    { name: 'The Dawn Vessel', src: '/music/The Dawn Vessel.wav' },
    { name: 'Mankind', src: '/music/Mankind.wav' },
    { name: 'Jeff\'s Old Diner', src: '/music/Jeff\'s Old Diner.wav' },
    { name: 'Exploration', src: '/music/Exploration.wav' },
    { name: 'Faith Sieled', src: '/music/Faith Sieled.wav' },
  ];

  // Set the first song as default when the component is rendered
  useEffect(() => {
    setCurrentSong(songs[0].src); // Load the first song by default
    setSelectedSongIndex(0); // Highlight the first song
  }, []);

  const handleImageClick = (index) => {
    setSelectedImage(images[index]);
    setCurrentIndex(index);
    onOpen();
  };

  const handleSongChange = (songSrc, index) => {
    setCurrentSong(songSrc);
    setSelectedSongIndex(index); // Highlight selected song
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setCurrentIndex(0);
    onClose();
  };

  const handleNextImage = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handlePreviousImage = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[newIndex]);
    setCurrentIndex(newIndex);
  };

  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-b, purple.700, pink.500, gray.900)"
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={4}
      pb={64}
      width="100%"
      overflowY="auto"
    >
      <Box flex="1" display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={12}>
        {/* Music Player */}
        <Box width="100%" mb={8}>
          <h2 className="soundtrack-title">The Official Zero Day Soundtrack</h2>
          <ul className="song-list">
            {songs.map((song, index) => (
              <li
                key={index}
                className={`song-item ${index === selectedSongIndex ? 'selected' : ''}`}
                onClick={() => handleSongChange(song.src, index)} // Highlight the selected song
              >
                {song.name}
              </li>
            ))}
          </ul>
          <AudioPlayer
            src={currentSong}
            onPlay={() => console.log('Playing')}
            customAdditionalControls={[]}
            autoPlayAfterSrcChange={false} // Prevent auto-playing on song change
            className="custom-audio-player"
          />
        </Box>

        <SimpleGrid columns={[2, null, 4]} spacing={10} mt={8} maxW="1200px">
          {images.map((src, index) => (
            <Box
              key={index}
              p={2}
         
              _hover={{ transform: 'scale(1.05)' }}
              transition="transform 0.2s ease-in-out"
              onClick={() => handleImageClick(index)}
              cursor="pointer"
              maxW={index >= 16 ? '150px' : '100%'}
              maxH={index >= 16 ? '150px' : 'auto'}
            >
              <Image src={src} alt={`Gallery image ${index + 1}`} objectFit="contain" />
            </Box>
          ))}
        </SimpleGrid>

        <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered>
          <ModalOverlay />
          <ModalContent
            bgGradient="linear(to-b, purple.700, pink.500, gray.900)"
            color="white"
            maxW="80%"
            maxH="80vh"
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ModalCloseButton
              position="absolute"
              top={2}
              right={2}
              backgroundColor="rgba(255, 255, 255, 0.7)"
              _hover={{ backgroundColor: 'white' }}
              color="black"
              borderRadius="full"
              zIndex="tooltip"
            />

            <ModalBody display="flex" justifyContent="center" alignItems="center" position="relative" p={4}>
              {selectedImage && (
                <Zoom>
                  <Image
                    src={selectedImage}
                    alt="Selected image"
                    maxW="100%"
                    maxH="60vh"
                    objectFit="contain"
                  />
                </Zoom>
              )}

              {/* Left Arrow Button */}
              <IconButton
                aria-label="Previous image"
                icon={<ArrowBackIcon />}
                position="absolute"
                left={2}
                top="50%"
                transform="translateY(-50%)"
                onClick={handlePreviousImage}
                backgroundColor="rgba(255, 255, 255, 0.7)"
                _hover={{ backgroundColor: 'white' }}
                color="black"
                borderRadius="full"
              />

              {/* Right Arrow Button */}
              <IconButton
                aria-label="Next image"
                icon={<ArrowForwardIcon />}
                position="absolute"
                right={2}
                top="50%"
                transform="translateY(-50%)"
                onClick={handleNextImage}
                backgroundColor="rgba(255, 255, 255, 0.7)"
                _hover={{ backgroundColor: 'white' }}
                color="black"
                borderRadius="full"
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default Gallery;
