import React, { useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { FaDownload, FaTwitter, FaInstagram, FaFacebook } from 'react-icons/fa';
import '../styles.css';

const Home = () => {
  useEffect(() => {
    // Dynamically load the LightWidget script
    const script = document.createElement('script');
    script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js';
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-b, purple.700, pink.500, gray.900)"
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={4}
      pb={24} // Add extra padding at the bottom
    >
      <h1 className="game-title">
        <span className="main-title">Zero Day</span>
        <span className="sub-title"> Humanity's Last Hope</span>
      </h1>

{/* Sub Zero: The Sequel Game Section */}
<Box
  textAlign="center"
  display="flex"
  flexDirection="column"
  justifyContent="center"
  alignItems="center"
  color="white"
  p={8}
  mt={8}
>
  <Box mb={4}>
    <img
      src="/images/icon_transparent.png"
      alt="Sub Zero Icon"
      style={{ width: '150px', height: '150px' }}
    />
  </Box>

  <h2 className="section-header" style={{ fontSize: '28px', marginBottom: '20px' }}>
    The Sequel Has Arrived: <strong>Sub Zero: The Game</strong>
  </h2>

  <Box className="promo-text" maxW="600px" textAlign="center" mb={6} lineHeight="1.8">
    <p>
      <strong>Sub Zero</strong> is the follow-up to <em>Zero Day</em>, taking you deeper into humanity’s struggle for survival.
      Set in 2147, Earth has become uninhabitable. Humanity’s last hope lies in Triton 631, a deep-sea research base.
    </p>
  </Box>

  <Button
    rightIcon={<FaDownload />}
    size="lg"
    bgGradient="linear(to-r, blue.400, teal.500)"
    color="white"
    _hover={{ bgGradient: 'linear(to-r, teal.600, blue.700)', transform: 'scale(1.05)' }}
    boxShadow="xl"
    fontFamily="Jersey10"
    textShadow="2px 2px 4px rgba(0, 0, 0, 0.7)"
    onClick={() =>
      window.open('https://apps.apple.com/se/app/sub-zero-the-game/id6736951257', '_blank')
    }
  >
    Download Sub Zero 🚀
  </Button>
</Box>


      {/* Place the GIF here */}
      <Box display="flex" justifyContent="center" alignItems="center" mt={3} mb={3}>
        <img
          src="/images/MainCharacterGif.gif"
          alt="Main Character Animation"
          className="promo-image-small"
        />
      </Box>

      <Box className="promo-text" maxW="600px" textAlign="center" mt={3} mb={3}>
        <p>
          Awaken aboard humanity’s final lifeline, a colossal spaceship carrying the remnants of
          Earth's life. As a young boy, you find yourself alone amidst a slumbering crew, tasked
          with solving intricate puzzles to unlock the ship's mysteries.
        </p>
      </Box>

      {/* Download Button */}
      <Button
        rightIcon={<FaDownload />}
        size="lg"
        bgGradient="linear(to-r, orange.400, pink.500)"
        color="white"
        _hover={{ bgGradient: 'linear(to-r, teal.600, blue.700)', transform: 'scale(1.05)' }}
        boxShadow="xl"
        fontFamily="Jersey10"
        mt={4}
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.7)"
        onClick={() =>
          window.open(
            'https://apps.apple.com/us/app/zero-day-the-game/id6670699407',
            '_blank'
          )
        }
      >
        Download for iOS 🚀
      </Button>


      

      {/* Social Media Icons */}
      <Box mt={8} mb={8} textAlign="center">
        <h2 className="section-header">Follow Us on Social Media</h2>
        <Box mt={4}>
          <Button
            as="a"
            href="https://x.com/zerodaythegame"
            target="_blank"
            leftIcon={<FaTwitter />}
            m={2}
            colorScheme="twitter"
          >
            X (Twitter)
          </Button>
          <Button
            as="a"
            href="https://instagram.com/zerodaythegame"
            target="_blank"
            leftIcon={<FaInstagram />}
            m={2}
            colorScheme="pink"
          >
            Instagram
          </Button>
          <Button
            as="a"
            href="https://www.facebook.com/profile.php?id=61565884776142&mibextid=LQQJ4d"
            target="_blank"
            leftIcon={<FaFacebook />}
            m={2}
            colorScheme="facebook"
          >
            Facebook
          </Button>
        </Box>
      </Box>

{/* Instagram Feed via SnapWidget */}
<Box mt={0} mb={0} width="100%" maxW="765px">
  <h2 className="section-header">Latest on Instagram</h2>
  <Box mt={2}>
    <iframe
      src="https://snapwidget.com/embed/1079527"
      className="snapwidget-widget"
      allowTransparency="true"
      frameBorder="0"
      scrolling="no"
      style={{ 
        border: 'none', 
        overflow: 'hidden', 
        width: '100%', 
        height: '250px', 
        margin: '0px !important', 
        padding: '0px !important', 
        display: 'block' 
      }}
      title="Posts from Instagram"
    ></iframe>
  </Box>
</Box>


{/* Speedrunning Section */}
<h2 className="section-header" style={{ marginTop: '50px' }}>
  Speedrunning
</h2>
<Box className="promo-text" maxW="600px" textAlign="center" mt={3} mb={3}>
  <p>
    Are you a speedrunner or interested in becoming one? Join the speedrunning community on{' '}
    <a
      href="https://www.speedrun.com"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: '#00BFFF' }}
    >
      Speedrun.com
    </a>{' '}
    and test your skills!
    <br />
    <br />
    The current world record for Zero Day is <strong>08:19.27</strong> (8 minutes and 19 seconds) in the any% category! Can you beat the record and etch your name into Zero Day history?
  </p>
</Box>
      

      {/* Remove the NEWS and app icon section */}
      
      <h2 className="section-header" style={{ marginTop: '20px', marginBottom: '20px' }}>Explore the Depths of Space</h2>
      <Box display="flex" flexDirection="column" alignItems="center" className="section-placeholder">
        <img 
          src="/images/picture1.PNG" 
          alt="Picture Image 1" 
          className="promo-image"
        />
        <Box className="promo-text" maxW="600px" textAlign="center" mt={3} mb={3}>
          <p>
            In Zero Day, you're not just solving puzzles—you're exploring a dying world. Dive deep into the mysteries of a spaceship that holds the key to humanity’s survival. What secrets will you uncover?
          </p>
        </Box>
      </Box>

      <h2 className="section-header" style={{ marginTop: '20px', marginBottom: '20px' }}>Unveil the Secrets of the Past</h2>
      <Box display="flex" flexDirection="column" alignItems="center" className="section-placeholder">
        <img 
          src="/images/picture2.PNG" 
          alt="Picture Image 2" 
          className="promo-image"
        />
        <Box className="promo-text" maxW="600px" textAlign="center" mt={3} mb={3}>
          <p>
            The fate of Earth hangs in the balance. As you journey through the ship, every log and every puzzle brings you closer to the truth. Can you decipher the past to save the future?
          </p>
        </Box>
      </Box>

      <h2 className="section-header" style={{ marginTop: '20px', marginBottom: '20px' }}>A Gripping Sci-Fi Puzzle Adventure</h2>
      <Box className="promo-text" maxW="600px" textAlign="center" mt={3} mb={3}>
        <p>
          Embark on a gripping sci-fi puzzle adventure in Zero Day. Wake up on a ship filled with the DNA of Earth's life, solve puzzles, and uncover the fate of humanity. Can you lead us to a new home?
        </p>
      </Box>

      {/* Move the PNG here */}
      <img 
        src="/images/MainCharacter.png" 
        alt="Game Promotion" 
        className="promo-image" 
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />

      <h2 className="section-header" style={{ marginTop: '30px', marginBottom: '20px' }}>Unveil the Story of Humanity’s Last Hope</h2>
      <Box className="promo-text" maxW="600px" textAlign="center" mt={3} mb={3}>
        <p>
          Awaken aboard humanity's last hope, a ship carrying the remnants of Earth's life. 
          As the winds of destruction echo in the void, unravel the story of our downfall, 
          solve intricate puzzles, and uncover the secrets of our search for a new home. 
          Can you restore hope in a world that was lost?
        </p>
      </Box>

      <div className="section-placeholder">
        <img 
          src="/images/picture3.PNG" 
          alt="Picture Image 3" 
          className="promo-image"
        />
      </div>

      {/* Short Reviews Section */}
      <Box className="reviews-section" maxW="600px" textAlign="center" mt={8} mb={4}>
        <h2 className="section-header" style={{ marginBottom: '20px' }}>What Players Are Saying</h2>
        
        <Box className="review" mt={4}>
          <p>⭐⭐⭐⭐⭐</p>
          <p>"Great puzzle game set aboard a mysterious spaceship. The game masterfully combines intricate puzzles and creates an atmosphere of claustrophobia and existential dread, capturing the feeling of human smallness in the face of the unknown and the incomprehensible."</p>
          <p><em>- Robin Veijolainen, BETA and UAT</em></p>
        </Box>
      </Box>

      <Box height="100px" /> {/* Blank space at the bottom */}
    </Box>
  );
};

export default Home;